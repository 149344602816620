import pic1 from "../assets/pic1.jpg";
import { BiSolidCategory } from "react-icons/bi";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { MdIntegrationInstructions } from "react-icons/md";
import { MdReviews } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { IoPricetag } from "react-icons/io5";

const cardsData = [
  {
    image: <MdOutlineQrCodeScanner size={60} className="mx-auto" />,
    title: "Scan to Use",
    description:
      "Customers can easily access our E-menu by scanning a QR code at their table with their smart mobile device, making it convenient and accessible for all.",
  },
  {
    // image: "https://via.placeholder.com/300",
    image: <BiSolidCategory className="mx-auto" size={60} />,
    title: "Categories",
    description:
      "Cranesoft E-menu is divided into food and drink sections, making it easy for customers to find what they are looking for.",
  },
  {
    image: <FaShoppingCart size={60} className="mx-auto"/>,
    title: "Add to Cart",
    description:
      "Customers can easily add items to their cart and modify their orders as needed.",
  },
  {
    image: <MdDeliveryDining size={60} className="mx-auto"/>,
    title: "Ordering and Delivery",
    description:
      "Cranesoft E-menu allows Customers to place their order and get it delivered to their table. This saves time and reduces the chance of errors in the order.",
  },
  {
    image: <MdIntegrationInstructions size={60} className="mx-auto"/>,
    title: "Integration",
    description:
      "Cranesoft E-menu can easily integrate with other hotel and restaurant software. This means that you can seamlessly incorporate our E-menu into your existing systems and processes.",
  },
  {
    image: <MdReviews size={60} className="mx-auto"/>,
    title: "Customer Reviews",
    description:
      "Cranesoft E-menu allows Customers to leave reviews of services on the menu. This helps other customers make informed decisions and improves the overall dining experience.",
  },
  {
    image: <RiAdminFill size={60} className="mx-auto"/>,
    title: "Admin/Owner Control",
    description:
      "Cranesoft E-menu allows owners or admins to easily review and change prices as needed.",
  },
  {
    image: <FaUserAlt size={60} className="mx-auto" />,
    title: "User-Friendly",
    description:
      "Cranesoft E-menu is designed with the user in mind, making it easy and intuitive to use.",
  },
  {
    image: <IoPricetag size={60} className="mx-auto"/>,
    title: "Pricing",
    description:
      "Customers can see the pricing of each item and the total price of their order in real-time, making it easy to stay within budget.",
  },
];

const Card = ({ image, title, description }) => {
  return (
    <div className="md:max-w-80 max-w-[300px] rounded-xl bg-[#E9F8F3] h-96 overflow-hidden shadow-lg mb-8  transform transition-transform hover:scale-105 ">
      {/* <img className="w-24 my-8 h-auto mx-auto" src={image} alt={title} /> */}
      <div className="mt-16  mx-auto" >{image}</div>
      <div className="px-6 py-4">
        <div className="font-bold text-green-800 text-xl text-center mb-2">{title}</div>
        <p className="text-gray-700 text-center text-base">{description}</p>
      </div>
    </div>
  );
};


const Features = () => {

  return (
    <section className= "md:max-w-[1480px] mb-12 m-auto max-w-[600px]" id="features">
      <h1 className="text-4xl text-center py-3 font-bold mb-12 mt-12">Features</h1>
    {/* <div className="flex gap-4 flex-wrap justify-center"> */}

    <div className="flex justify-center">
    <div className="grid md:grid-cols-3 gap-12 justify-center">
      {cardsData.map((card, index) => (
        <Card
          key={index}
          image={card.image}
          title={card.title}
          description={card.description}
        />
      ))}
    </div>
    </div>
    </section>
  );
};

export default Features;
