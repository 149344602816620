import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { Link as ScrollLink } from "react-scroll";
import cranesoft from "../assets/cranesoft.png"

const Navbar = () => {

  const [toggle, setToggle] = useState(false);
  const handleClick = () => setToggle(!toggle);
  


  return (
    <div className="max-w-[100vw] h-[80px] bg-white px-8 md:px-24 border">
      <div className="md:max-w-[1480px] max-w-[600px] m-auto w-full h-full flex justify-between items-center md:px-0 px-4 md:p-8 sm:p-2">
        {/* <h1 className="h-[25px] text-green-800">Our Menu</h1> */}
        <img className="h-[25px]" src={cranesoft}  />

        <div className="hidden md:flex item-center ">
          <div className="flex  items-center gap-8">
            <ul className="flex  gap-8  ">
              <a href="/">
                <li className="cursor-pointer">Home </li>
              </a>
              <ScrollLink to="features" smooth={true} duration={1000}>
                <li className="cursor-pointer">
                  Features
                </li>
              </ScrollLink>
              
              <ScrollLink to="pricing" smooth={true} duration={1000}>
                <li className="cursor-pointer">
                Plans and Pricing
                </li>
              </ScrollLink>
                  
                
          
              <ScrollLink to="contact" smooth={true} duration={1000}>
                <li className="cursor-pointer">
                  Contact
                </li>
              </ScrollLink>
            </ul>
            

          </div>
          
          <a href="/get-started"><button className="border border-green-500 border-2 py-1 text-center px-6 mx-8 rounded-xl">
              Get Started
          </button></a>
          
        </div>

        <div className="md:hidden" onClick={handleClick}>
          {toggle ? <IoCloseSharp /> : <FaBars />}
        </div>
      </div>

      <div className={toggle ? "bg-white z-50 absolute overflow-hidden p-4 w-full md:hidden" : "hidden"}>
        <ul className="">
        <a href="/">
                <li className="cursor-pointer p-4 hover:font-bold">Home </li>
              </a>
              <ScrollLink to="features" smooth={true} duration={1000}>
                <li className="cursor-pointer p-4 hover:font-bold">
                  Features
                </li>
              </ScrollLink>
              
              <ScrollLink to="pricing" smooth={true} duration={1000}>
                <li className="cursor-pointer p-4 hover:font-bold">
                Plans and Pricing
                </li>
              </ScrollLink>
                  
                
          
              <ScrollLink to="contact" smooth={true} duration={1000}>
                <li className="cursor-pointer p-4 hover:font-bold">
                  Contact
                </li>
              </ScrollLink>
          {/* <li className="p-4 hover:font-bold">More</li> */}
          {/* <div className="flex flex-col my-4 gap-4 justify-center"> */}
          <a href="/get-started"><button className="border border-green-500 border-2 py-1 px-6 rounded-xl">
           Get Started
          </button></a>
          {/* </div> */}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

