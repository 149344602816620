import React from "react";
import logo1 from "../assets/logo1.png";

const Card = ({prtner}) => {
  return (
    <div className="drop-shadow-2xl z-10 overflow-hidden  my-4 mx-8">
      <img src={prtner.linkImg} className="h-20  m-auto " />

    </div>
  );
};

export default Card;
