
import React, { useEffect } from "react";
import logo2 from "../assets/logo2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "./TestimonialCard.jsx";
import { BsFillPeopleFill } from "react-icons/bs";
import testimony from "../data/Testimony.js";


function Testimonials() {
  
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <div className="w-full md:p-24 bg-white py-32">
      <div className=" md:max-w-[1480px] m-auto max-w-[600px] px-8 overflow-hidden">
        <BsFillPeopleFill className="text-green-800 text-7xl mx-auto" />
        <h1 className="text-3xl text-center py-3 font-bold">
          Clients' <span className="text-green-800">Feedback</span> :
        </h1>

        <Slider {...settings}>
        {testimony.map((testimonial) => (
    <TestimonialCard
      key={testimonial.id}
      name={testimonial.name}
      company={testimonial.company}
      quote={testimonial.quote}
      img={testimonial.img}
    />
  ))}
          {/* <TestimonialCard />
          <TestimonialCard /> */}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonials;
