import React from "react";
import herobg from '../assets/herobg.jpg'
function Hero() {
  return (
    <div className="max-w-[100vw]  flex bg-cover md:bg-center bg-opacity-5 h-[100vh]   md:p-24 py-24" style={{ backgroundImage: `url(${herobg})` }}>
      <div className=" md:max-w-[1480px] mx-auto items-center grid md:grid-cols-2 max-w-[600px]">
        <div className="flex flex-col  opacity-100 gap-4">
          <h1 className="text-6xl text-green-800 mx-4 font-bold ">
            Your One-Stop Digital Menu Solution.
          </h1>
          <p className="font-semibold text-xl mx-4  ">
            Our digital menu solution, simplifying menu management and
            enhancing the dining experience for restaurants, bars, lounges and
            hotels.
          </p>
          <p className="text-lg mx-4">Digital Menu | Cashier Display | Customer's Feedback</p>
          <div>
          {/* <button className="mx-4 border p-4 shadow-lg rounded-sm">Learn More</button> */}
          <a href="/get-started"><button className="border p-4 mx-4  bg-green-800  w-[80%] text-center text-white text-lg shadow-lg rounded-lg">Get Started</button></a>
          </div>
        </div>
        
      </div>
    </div>
    
  );
}

export default Hero;
