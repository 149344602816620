import React, { useEffect } from "react";
import logo2 from "../assets/logo2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "./Card";
import partner from "../data/Partner.js";
// eslint-disable-next-line no-unused-vars
// import partner from "../data/Partner.js"

function Partners() {
  // useEffect(() => {
  //     const slider = document.querySelector(".slick-slider");
  //     slider && window.innerWidth < 1024 && slider.slickGoTo(1);
  //   }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <div className="w-full md:p-24 bg-[#E9F8F3] py-32">
      <div className=" md:max-w-[1480px] m-auto max-w-[600px] md:px-0 overflow-hidden">
        <div className="py-4">
        <h1 className="text-3xl text-center py-3 font-bold mb-12">
          Trusted by the following{" "}
          <span className="text-green-800">Brands</span> :
        </h1>
        </div>

        <Slider {...settings}>
          {partner.map((prtner) => (
            <Card prtner={prtner} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Partners;
