import logo1 from "../assets/logo1.png"
import logo2 from "../assets/logo2.png"
import logo4 from "../assets/logo4.png"
import logo5 from "../assets/logo5.png"
import logo6 from "../assets/logo6.jpg"
const partner = [
    {
      id: 1,
      title: 'The Complete Flutter Development Bootcamp with Dart',
      category:'Flutter',
      rating:5,
      price: '$59.99',
      linkImg: logo1
    },
    {
      id: 2,
      title: 'The Complete 2023 Web Development Bootcamp',
      category:'Web Dev',
      rating:4,
      price: '$59.99',
      linkImg: logo2
    },
    {
      id: 3,
      title: 'Learn Python: The Complete Python Programming CourseLearn A-Z everything about Python, from the basics, to advanced topics like Python GUI, Python Data Analysis, and more!',
      category:'Python',
      rating:4,
      price: '$59.99',
      linkImg: logo4
    },
    {
      id: 4,
      title: 'The Complete JavaScript Course 2023: From Zero to Expert!',
      category:'Javascript',
      rating:4,
      price: '$59.99',
      linkImg: logo5
    },
    {
      id: 5,
      title: 'Cuphead',
      category:'Flutter',
      rating:4,
      price: '$19.99',
      linkImg: logo6
    }
    
  ];

export default partner