import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const CompanyMap = () => {
  const position = [6.592134, 3.361704]; // Replace with your company's latitude and longitude

  return (
    <MapContainer center={position} zoom={13} style={{ height: '400px', width: '70vw', margin: '50px auto' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>Your Company</Popup>
      </Marker>
    </MapContainer>
  );
};

export default CompanyMap;
