import React from "react";

function Companies() {
  return (
    <div className="w-full md:p-24 py-4">
      <div className=" md:max-w-[900px] p-8 md:p-2 m-auto max-w-[600px]">
        <h1 className="text-center text-green-800 text-4xl mb-4 font-bold">
          What is a Digital Menu?
        </h1>
        <p className="text-center text-xl  ">
       {/* It is a digitalized menu that enables customers place order by simply scanning a QR code with their mobile phone and gets their order within minutes. */}
       
A digital menu is an electronic version of a traditional paper menu, displayed on a mobile screen. It offers interactive features like ordering and customization, providing a more engaging dining experience.
        </p>
      </div>
    </div>
  );
}

export default Companies;
