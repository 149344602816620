import avatar  from "../assets/avatar.png"
import logo2 from '../assets/logo2.png'
import { RxAvatar } from "react-icons/rx"

const testimony = [
    {
      id: 1,
      img: avatar,
      name: "",
      company: "Box residence",
      quote: "The E-menu has made our dining experience easier at Box residence. It eradicated the old menu paper, saved us cost of reprinting and of course our customers have a better experience placing their order with just one click. The integration with our hotel software also makes the work easier for us to just upload the menu in one location and it reflects on the e-menu app."
    },
    {
      id: 2,
      img: avatar,
      name: "",
      company: "Chesterfield",
      quote: "This is the best e-menu you can ever have. Cranesoft E-menu has made ordering of meals easier and more efficient, it has helped our customers to make quick and informed decisions about what they want to eat and drink."
    },
    {
        id: 3,
        img: avatar,
        name: "",
        company: "Inside Bar",
        quote: "Our Customers have amazing experience placing order for their meals. The app is very easy to navigate."
      },
  
  ];
  
  export default testimony;
  