// import React from "react";
// import adv1 from "../assets/adv1.png";
import adv2 from "../assets/adv2.png";
import POS from "../assets/POS.png";
import QR1 from "../assets/QR1.png"
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Advantages() {
  const details = [
    {
      bigText: "A SMARTER, FASTER WAY TO ORDER.",
      smallText: "Simplicity and Visibility",
      details:
        "Our solution is user-friendly and offers easy access to evaluate your business. With contactless transactions and time-efficient processes, you can expect improved customer experience",
      img: adv2,
    },
    {
      bigText: "TRANSFORM YOUR BUSINESS WITH DIGITAL MENUS",
      smallText: "Business Management",
      details:
        "By eliminating menu printing costs and having access to real-time menu and price, our software helps you manage your business more effectively. Additionally, it helps to redefine your business focus.",
      img: QR1,
    },
    {
      bigText: "ACCEPTS ORDERS & PAYMENTS",
      smallText: "Seamless Ordering",
      details:
      "Effortlessly Accept Orders and Payments with Our E-Menu Solution. Streamline your business operations and enhance customer experience by integrating a seamless ordering and payment system into your digital menu.",
      img: POS,
    },
  ];
  const { ref, inView } = useInView({
    threshold: 0.05, // Trigger animation when 50% of the component is in view
    triggerOnce: true, // Only trigger once
  });
  return (
    
    <div className=" md:p-24 py-24 mx-auto bg-[#E9F8F3] overflow-hidden">
      {details.map((rd, index) => (
        
        <div
          key={index}
          className={`md:max-w-[1480px] mx-auto max-w-[600px] md:flex gap-12 mb-12 max-w-[600px] mb-12 ${
            index % 2 === 0 && "md:flex-row-reverse"
          } `}
        > 
          <div className={`flex flex-col justify-center text-center items-center gap-4 md:w-[50%] p-8 mx-auto`}> <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 100 }}
        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
            <h1 className="text-lg mb-4 text-green-800 mx-auto font-bold">
              {rd.bigText}
            </h1>
            <p className="font-semibold mb-2 text-4xl text-center mx-4">
              {rd.smallText}
            </p>
            <p className="font-semibold mx-auto text-center mx-4">{rd.details}</p>
            </motion.div>
          </div>
          
          
          <div className="md:w-[50%]  px-8  mx-auto">
            <motion.div
        ref={ref}
        initial={{ opacity: 0, x: -100 }} // Initial state: hidden and moved to the left
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }} // Animate opacity and x position based on inView state
        transition={{ duration: 0.5, ease: "easeInOut" }} // Duration of 0.5 seconds with ease-in-out easing
      >
            <img className="mx-auto" src={rd.img} alt="hero" />
            </motion.div>
          </div>
        </div>
      ))}
    </div>
    
  );
}

export default Advantages;

