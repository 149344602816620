import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import GetStarted from "./pages/GetStarted";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/get-started",
    element: <GetStarted />,
  }
]);
