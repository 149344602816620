import { Contact, Footer, Map, Subscribe } from "../components"

const GetStarted = () => {
  return (
  <div>
    <div className="bg-black text-white h-[200px] p-8">
      <h1 className=" text-4xl font-bold mb-4">Contact Us</h1>
      <p className=""><a href="/">Home {'> '}</a>Contact Us</p>
    </div>
    <Contact /> 
    <Subscribe />
    <Map />
    <Footer />
  </div>
  )
}

export default GetStarted