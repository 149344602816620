import React from "react";
import { FaQuoteRight } from "react-icons/fa6";


const TestimonialCard = ({ name, company, quote, img }) => {
  return (
    <div className="bg-white p-8 shadow-xl rounded-xl my-8 mx-2 flex flex-col justify-between" style={{ height: "500px" }}>
      <div className="flex gap-4">
        <img
          className="h-10 w-10 rounded-full"
          src={img}
          alt=""
        />
       
      <div>
          <h1>{name}</h1>
          <p className="italic font-semibold">{company}</p>
      </div>
        
      
      <div className="flex flex-grow justify-end">
        <FaQuoteRight className="text-3xl text-green-800" />
      </div>
      </div>
      
      <div className="flex-grow">
        <h3 className="text-lg mt-4">
          {quote}
        </h3>
      </div>
      
      
    </div>
  );
};

export default TestimonialCard;
